import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import {
  ModalHeader,
  ModalBody,
} from '@veit/veit-web-controls';

import ModalActions, { finishDialogAddDevice } from '../../store/ModalDevice.actions';
import { expandLocation, modalGetLocation } from '../../store/Location.actions';
import {
  deviceType, deviceTypeName, unitType, roleType,
} from '../../model/enums';
import { goTo } from '../../store/Router.actions';
import settings from '../../settings';

import bem from '../../utils/bem';

import SubmitHandler, { keyCodes } from '../../components/SubmitHandler';
import SelectDeviceType from './SelectDeviceType';
import ModalWithLoader from '../ModalWithLoader';
import Footer from '../Footer';
import Step1, { hasStep1Error } from './Step1';
import Success from './Success';

const bm = bem.modal('add-device');

class AddDeviceModal extends Component {
  steps = 1;

  componentDidMount() {
    this.fetchLocations();
  }

  fetchLocations = () => {
    this.props.modalGetLocation({ expand: expandLocation.farm });
  }

  getLink = link => (...chunks) => {
    return (
      <a
        onClick={() => this.cancel()}
        href={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        {chunks}
      </a>
    );
  };

  getSuccessSubtitle = () => {
    const { type } = this.props.modal.data;
    return type !== deviceType.bat2Gsm
      ? (
        <FormattedMessage
          values={{ a: this.getLink(settings.help.import) }}
          id="devices.modal.how-to-import"
          defaultMessage="How to import data from memory stick is described <a>here</a>."
        />
      )
      : (
        <FormattedMessage
          values={{ a: this.getLink(settings.help.addGsm) }}
          id="devices.modal.configure-gsm-scale"
          defaultMessage="You need to <a>configure your GSM Scale</a> now."
        />
      );
  }

  getStep = () => {
    const { role } = this.props.user;
    switch (this.props.modal.step) {
      case 1:
        return <Step1 modal={this.props.modal} fetchLocations={this.fetchLocations} />;
      case 2:
        return <Success subtitle={this.getSuccessSubtitle()} goTo={() => this.props.goTo(`/scale/${this.state.id}`)} />;
      default:
        return (
          <React.Fragment>
            <SelectDeviceType
              onSelect={this.selectDeviceType}
              disableLora={!roleType.isAdmin(role)}
            />
            <SubmitHandler keyCode={keyCodes.escape} action={this.cancel} />
          </React.Fragment>
        );
    }
  }

  cancel = () => {
    this.props.cancelDialog();
  }

  finish = () => {
    const { data } = this.props.modal;
    this.props.finishDialogAddDevice(data).then((device) => {
      if (!data.fast) {
        this.setState({ id: device.id });
        this.props.setStep(2);
      }
    });
  }

  selectDeviceType = (type) => {
    if (type === deviceType.bat2Connect || type === deviceType.bat2Gsm
       || type === deviceType.bat2 || type === deviceType.bat2Lora
       || type === deviceType.bat2BlackBox) {
      this.props.updateFormObject({ type, unit: unitType.kg });
      this.props.setStep(1);
    } else {
      this.props.cancelDialog();
      window.open(settings.help.addCable, '_blank');
    }
  }

  render() {
    const { step } = this.props.modal;
    const { type } = this.props.modal.data;
    const footer = step < 1 || step > 1 ? null : (
      <Footer
        step={step || type == null}
        maxStep={this.steps}
        finisDialog={this.finish}
        finisDialogText={<FormattedMessage id="devices.add-scale" defaultMessage="Add Scale" />}
        modal={this.props.modal}
        validators={{
          1: hasStep1Error,
        }}
      />
    );

    return (
      <ModalWithLoader
        centered
        isOpen
        keyboard={false}
        toggle={this.cancel}
        contentClassName="modal-medium"
        className={bm.b()}
      >
        {
          <ModalHeader toggle={this.cancel}>
            {
              type == null
                ? <FormattedMessage id="devices.add-scale" defaultMessage="Add Scale" />
                : step === 2
                  ? null
                  : <FormattedMessage values={{ type: deviceTypeName[type] }} id="common.add-type" defaultMessage="Add {type}" />
            }
          </ModalHeader>
        }
        {/* {steps} */}
        <ModalBody style={step === 0 ? { maxWidth: 'unset' } : null}>
          {this.getStep()}
        </ModalBody>
        {footer}
      </ModalWithLoader>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  cancelDialog: ModalActions.cancelDialog,
  updateFormObject: ModalActions.updateFormObject,
  setStep: ModalActions.setStep,
  finishDialogAddDevice,
  modalGetLocation,
  goTo,
}, dispatch);


AddDeviceModal.propTypes = {
  cancelDialog: PropTypes.func.isRequired,
  updateFormObject: PropTypes.func.isRequired,
  finishDialogAddDevice: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  modalGetLocation: PropTypes.func.isRequired,
  goTo: PropTypes.func.isRequired,
  user: PropTypes.shape({
    role: PropTypes.string,
  }).isRequired,
  modal: PropTypes.shape({
    data: PropTypes.object,
    type: PropTypes.string,
    target: PropTypes.string,
    step: PropTypes.number,
  }).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDeviceModal);
