import React from 'react';
import PropTypes from 'prop-types';
import validator from 'validator';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { FormattedMessage } from 'react-intl';
import {
  Label,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
} from '@veit/veit-web-controls';

import SelectLocation from '../../components/SelectLocation';
import FormLabel from '../../components/FormLabel';
import { deviceType, unitType } from '../../model/enums';
import ModalActions, { openDialogAddLocation } from '../../store/ModalLocation.actions';
import { disableKey, enableKey } from '../../utils/keyEvents';

const devEuiLength = 16;
const serialNumberLength = 20;

function validatePhone(phone) {
  const number = parsePhoneNumberFromString(phone || '');
  return number != null && number.isValid();
}

// BlackBox's serial number is consists of prefix BB: and it MAC address,
// so you can't check only MAC address, but also the prefix
function validateBlackBoxSerialNumber(number) {
  const snRegex = new RegExp('^([bB]){2}:([0-9a-fA-F][0-9a-fA-F]:){5}([0-9a-fA-F][0-9a-fA-F])$');
  return snRegex.test(number);
}

function formatPhoneNumber(e) {
  const number = e.target.value;
  if (number == null) return '+';
  const formatted = number.trim();
  return formatted[0] === '+' ? formatted : `+${formatted}`;
}

export function hasStep1Error({
  name, phoneNumber, type, locationId, devEui, serialNumber,
}) {
  return {
    name: name == null || !validator.isLength(validator.trim(name), { min: 1 }),
    phoneNumber: type === deviceType.bat2Gsm && !validatePhone(phoneNumber),
    devEui: type === deviceType.bat2Lora && !validator.isLength(devEui || '', { min: devEuiLength, max: devEuiLength }),
    region: type === deviceType.bat2Lora && !validator.isLength(devEui || '', { min: 3 }),
    locationId: locationId == null,
    serialNumber: (type === deviceType.bat2Connect && !validator.isLength(serialNumber || '', { min: serialNumberLength, max: serialNumberLength }))
               || (type === deviceType.bat2BlackBox && !validateBlackBoxSerialNumber(serialNumber)),
  };
}

const Step1 = ({
  modal, locations, updateForm, updateFormEvent, fetchLocations, openDialogAddLocation,
}) => {
  const form = modal.data;
  if (form == null) return null;
  const hasErrors = hasStep1Error(form);
  const edit = form.id != null;

  const addLocationAction = (location) => {
    if (location != null) {
      fetchLocations();
      updateForm(location.id, 'locationId');
    }
  };

  if (form.type === deviceType.bat2Connect
    || form.type === deviceType.bat2BlackBox) {
    // BAT2 Connect and BlackBox use always grams
    form.unit = unitType.g;
  }

  return (
    <React.Fragment>
      <FormLabel required={hasErrors.name}>
        <FormattedMessage id="common.name" defaultMessage="Name" />
      </FormLabel>
      <Input className="form-control" type="text" value={form.name || ''} onChange={e => updateFormEvent(e, 'name')} autoFocus />
      <FormLabel required={hasErrors.locationId}>
        <FormattedMessage id="modals.select-location" defaultMessage="Select Location" />
      </FormLabel>
      <SelectLocation
        locations={locations}
        location={locations.find(f => f.id === form.locationId)}
        onChange={c => updateForm((c || {}).id, 'locationId')}
        addLocationAction={() => openDialogAddLocation().then(addLocationAction)}
      />
      {(form.type === deviceType.bat2Connect || form.type === deviceType.bat2BlackBox) && (
        <React.Fragment>
          <FormLabel required={hasErrors.serialNumber}>
            <FormattedMessage id="common.serial-number" defaultMessage="Serial number" />
          </FormLabel>
          <Input
            disabled={edit}
            type="text"
            value={(form.serialNumber || '').toUpperCase()}
            maxLength="20"
            onChange={e => updateForm(e.target.value, 'serialNumber')}
          />
        </React.Fragment>
      )}
      {form.type === deviceType.bat2Gsm && (
        <React.Fragment>
          <FormLabel required={hasErrors.phoneNumber}>
            <FormattedMessage id="common.phone-number" defaultMessage="Phone number" />
          </FormLabel>
          <Input type="text" value={form.phoneNumber || '+'} onChange={e => updateForm(formatPhoneNumber(e), 'phoneNumber')} autoFocus />
          {!edit && (
            <Label type="info">
              <FormattedMessage id="devices.modal.phone-number-info" defaultMessage="Fill a phone number of SIM card in your GSM Scale" />
            </Label>
          )}
          <Label type="text">
            <FormattedMessage id="devices.modal.metric-system" defaultMessage="Metric system" />
          </Label>
          <UncontrolledDropdown>
            <DropdownToggle color="primary" outline caret>
              {
                form.unit === unitType.lb
                  ? <FormattedMessage id="common.pounds" defaultMessage="pounds" />
                  : <FormattedMessage id="common.kilograms" defaultMessage="kilograms" />
              }
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => updateForm(unitType.kg, 'unit')}>
                <FormattedMessage id="common.kilograms" defaultMessage="kilograms" />
              </DropdownItem>
              <DropdownItem onClick={() => updateForm(unitType.lb, 'unit')}>
                <FormattedMessage id="common.pounds" defaultMessage="pounds" />
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          {!edit && (
            <Label type="info">
              <FormattedMessage id="devices.modal.metric-system-info" defaultMessage="Choose a metric system of your GSM Scale" />
            </Label>
          )}
        </React.Fragment>
      )}
      {form.type === deviceType.bat2Lora && (
        <React.Fragment>
          <FormLabel required={hasErrors.devEui}>
            <FormattedMessage id="common.device-eui" defaultMessage="Device EUI" />
          </FormLabel>
          <Input
            disabled={edit}
            type="text"
            value={(form.devEui || '').toUpperCase()}
            maxLength="16"
            onChange={e => updateForm(e.target.value.toLowerCase(), 'devEui')}
          />
          {!edit && (
            <Label type="info">
              <FormattedMessage id="devices.modal.lora-device-eui-info" defaultMessage="LoRa device EUI" />
            </Label>
          )}
        </React.Fragment>
      )}
      <Label type="text">
        <FormattedMessage id="common.description" defaultMessage="Description" />
      </Label>
      <Input
        type="textarea"
        value={form.description || ''}
        onChange={e => updateFormEvent(e, 'description')}
        style={{ margin: '12px 0' }}
        onFocus={() => disableKey(13)}
        onBlur={() => enableKey(13)}
      />
      {!edit && (
        <Label type="info">
          <FormattedMessage id="modals.description-info" defaultMessage="Additional information" />
        </Label>
      )}
    </React.Fragment>
  );
};

Step1.propTypes = {
  modal: PropTypes.shape({
    data: PropTypes.object,
  }).isRequired,
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
  updateForm: PropTypes.func.isRequired,
  updateFormEvent: PropTypes.func.isRequired,
  fetchLocations: PropTypes.func.isRequired,
  openDialogAddLocation: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  locations: state.location.modal,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  updateForm: ModalActions.updateForm,
  updateFormEvent: ModalActions.updateFormEvent,
  openDialogAddLocation,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Step1);
