import React from 'react';
import PropTypes from 'prop-types';
import {
  ModalBody, Container, Row, Col,
} from '@veit/veit-web-controls';
import IMGsm from '@veit/veit-web-controls/dist/icons/IMGsm';
import IMBat2Stick from '@veit/veit-web-controls/dist/icons/IMBat2Stick';
import IMBat2Connect from '@veit/veit-web-controls/dist/icons/IMBat2Connect';
import IMBat2BlackBox from '@veit/veit-web-controls/dist/icons/IMBat2BlackBox';

import bem from '../../utils/bem';
import { deviceType, deviceTypeName } from '../../model/enums';

const bm = bem.modal('add-device');

const DeviceBlock = ({
  icon: Icon, type, onSelect, iconRotate, md,
}) => {
  return (
    <Col xs="12" md={md}>
      <div tabIndex={0} role="button" className="device-type" onKeyPress={() => onSelect(type)} onClick={() => onSelect(type)}>
        <Icon transform={iconRotate ? 'scale(-1,1)' : null} />
        <div className={bm.e('device-title')}>{deviceTypeName[type]}</div>
      </div>
    </Col>
  );
};

DeviceBlock.propTypes = {
  icon: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  iconRotate: PropTypes.bool,
  md: PropTypes.string,
};

DeviceBlock.defaultProps = {
  iconRotate: false,
  md: '6',
};


const SelectDeviceType = ({ onSelect, disableLora }) => {
  const md = disableLora ? '6' : '4';
  return (
    <ModalBody className={bm.e('select-type')}>
      <Container>
        <Row>
          <DeviceBlock
            icon={IMBat2Connect}
            type={deviceType.bat2Connect}
            onSelect={onSelect}
            md={md}
          />
          <DeviceBlock
            icon={IMGsm}
            type={deviceType.bat2Gsm}
            onSelect={onSelect}
            md={md}
          />
          <DeviceBlock
            icon={IMBat2Stick}
            type={deviceType.bat2}
            onSelect={onSelect}
            md={md}
          />
          <DeviceBlock
            icon={IMBat2BlackBox}
            type={deviceType.bat2BlackBox}
            onSelect={onSelect}
            md={md}
          />
          {!disableLora && (
            <DeviceBlock
              icon={IMGsm}
              iconRotate
              type={deviceType.bat2Lora}
              onSelect={onSelect}
              md={md}
            />
          )}
        </Row>
      </Container>
    </ModalBody>
  );
};


SelectDeviceType.propTypes = {
  disableLora: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default SelectDeviceType;
